import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_INVENTORY_DATA_MUTATION } from "../../graphql/mutation/user";
import CloseIcon from "@mui/icons-material/Close";
import {
  GET_ALL_INVENTORY,
  GET_INVENTORY_BY_BOX_NUMBER,
} from "../../graphql/queries/user";
import { scanImageData } from "@undecaf/zbar-wasm";
import useDialog from "../../hooks/useDialog";

const useStyles = makeStyles(() => ({
  paper: {
    position: "absolute",
    maxWidth: 700,
    width: "98%",
    backgroundColor: "white",
    padding: "30px",
    outline: "none",
    borderRadius: "10px",
  },
}));

function getModalStyle() {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  };
}

const getHeading = (scannerType) => {
  switch (scannerType) {
    case "RECEIVED": {
      return "Mark as Received";
    }
    case "STAGED": {
      return "Mark as Staged";
    }
    case "EMPTIED": {
      return "Mark as Emptied";
    }
    case "QUARANTINED": {
      return "Mark as Quarantined";
    }
    case "CYCLE_COUNT": {
      return "Mark as Cycle Count";
    }
    default: {
      return "Scanner";
    }
  }
};

const getUpdateInput = (scannerType) => {
  switch (scannerType) {
    case "RECEIVED": {
      return {
        status: "Received",
      };
    }
    case "STAGED": {
      return {
        status: "Staged",
      };
    }
    case "EMPTIED": {
      return {
        status: "Emptied",
      };
    }
    case "QUARANTINED": {
      return {
        status: "Quarantined",
      };
    }
    case "CYCLE_COUNT": {
      return {
        cycleCount: "YES",
      };
    }
    default: {
      return null;
    }
  }
};

function ScannerModal(props) {
  const { open, handleClose, scannerType, queryFilters } = props;
  const [error, setError] = useState("");
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);
  const [getByBoxNumner, { loading: fetchingInventory }] = useLazyQuery(
    GET_INVENTORY_BY_BOX_NUMBER
  );
  const { showAlert, showConfirm, DialogComponent } = useDialog();

  const [updateInventoryData, { loading: updatingInventory }] = useMutation(
    UPDATE_INVENTORY_DATA_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_ALL_INVENTORY,
          variables: {
            input: {
              filters: queryFilters,
            },
          },
        },
      ],
    }
  );

  useEffect(() => {
    if (!open) {
      setError("");
    }
  }, [open]);

  useEffect(() => {
    startCamera();
  }, []);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "environment",
        },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      streamRef.current = stream;
    } catch (error) {
      console.log(error);
    }
  };

  const stopCamera = () => {
    streamRef.current?.getTracks().forEach((track) => {
      track.stop();
      streamRef.current.removeTrack(track);
    });
  };

  const captureFrame = async () => {
    if (!videoRef.current || !canvasRef.current) return;
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    // Set the canvas size to match video
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    // Draw the full video frame onto the canvas
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    // Define the box's dimensions (adjust these as needed)
    const boxWidth = canvas.width * 0.7; // 60% of video width
    const boxHeight = boxWidth * 0.2; // Keep a landscape ratio (e.g., 3:2)
    const boxX = (canvas.width - boxWidth) / 2; // Center horizontally
    const boxY = (canvas.height - boxHeight) / 2; // Center vertically
    // Crop only the bordered box area
    const croppedImageData = ctx.getImageData(boxX, boxY, boxWidth, boxHeight);
    // Create a new cropped canvas
    const croppedCanvas = document.createElement("canvas");
    const croppedCtx = croppedCanvas.getContext("2d");
    if (!croppedCtx) return;
    // Set cropped canvas size
    croppedCanvas.width = boxWidth;
    croppedCanvas.height = boxHeight;
    // Draw the cropped portion onto the new canvas
    croppedCtx.putImageData(croppedImageData, 0, 0);
    return croppedImageData;
  };

  const scanBarcode = async (imageBitmap) => {
    if (window["BarcodeDetector"]) {
      const detector = new window.BarcodeDetector();
      const barcodes = await detector.detect(imageBitmap);
      if (!barcodes.length) {
        throw new Error("Not scanned, please try again!");
      }
      const value = barcodes[0].rawValue;
      if (!value) {
        throw new Error("Scanned but no value, please try again!");
      }
      return value;
    } else {
      const symbols = await scanImageData(imageBitmap);
      const value = symbols?.[0]?.decode();
      if (!value) {
        throw new Error("Scanned but no value, please try again!");
      }
      return value;
    }
  };

  const scanAndUpdate = async () => {
    try {
      const imageBitmap = await captureFrame();
      const scannedBoxNumber = await scanBarcode(imageBitmap);
      const { data, error } = await getByBoxNumner({
        variables: {
          input: {
            id: scannedBoxNumber,
          },
        },
      });
      if (error) {
        throw new Error(error);
      }
      const { id, boxNumber, productCode, cycleCount } =
        data.getAdminInventoryByBoxNumber;
      if (scannerType === "CYCLE_COUNT" && cycleCount === "YES") {
        throw new Error("Already marked as cycle count!");
      }
      const proceed = await showConfirm(
        `${boxNumber}, ${productCode}, ${getHeading(scannerType)}`
      );
      if (proceed) {
        const updatePayload = getUpdateInput(scannerType);
        const response = await updateInventoryData({
          variables: {
            input: {
              ids: [id],
              status: props.status,
              ...updatePayload,
            },
          },
        });
      }
    } catch (error) {
      await showAlert(error.message);
    }
  };

  const styles = useStyles();

  const handleSubmit = async (e) => {
    e.preventDefault();
    scanAndUpdate();
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        stopCamera();
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.paper} style={getModalStyle()}>
        <Box sx={{ position: "relative" }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {getHeading(scannerType)}
          </Typography>

          <CloseIcon
            style={{
              backgroundColor: "rgba(0,0,0,0.12)",
              borderRadius: "20px",
              position: "absolute",
              right: 0,
              top: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              stopCamera();
              handleClose();
            }}
          />
        </Box>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <div
            style={{
              position: "relative",
            }}
          >
            <video
              ref={videoRef}
              playsInline
              autoPlay
              style={{
                width: "100%",
                height: "50%",
                objectFit: "cover",
              }}
            ></video>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "70%",
                height: "20%",
                transform: "translate(-50%, -50%)",
                border: "2px solid red",
                boxSizing: "border-box",
              }}
            />
          </div>
          <canvas ref={canvasRef} style={{ display: "none" }} />
          {error && (
            <Box>
              <Typography component="p" variant="p" sx={{ color: "red" }}>
                {error}
              </Typography>
            </Box>
          )}
          <Button
            variant="contained"
            sx={{
              margin: "20px 0",
            }}
            type="submit"
            onClick={handleSubmit}
            disabled={fetchingInventory || updatingInventory}
          >
            {fetchingInventory || updatingInventory ? "Loading..." : "Scan"}
          </Button>
        </Box>
        <DialogComponent />
      </div>
    </Modal>
  );
}

export default ScannerModal;
