// import Button from "@mui/material/Button";
import { useEffect } from "react";
import Routes from "./Routes";
import { UserProvider } from "./services/UserContext";
// import { BarcodeDetectorPolyfill } from "@undecaf/barcode-detector-polyfill";

// const handleClick = (e) => {
//   e.preventDefault();

//   var win = document.getElementsByTagName("iframe")[0].contentWindow;
//   // var obj = {
//   //   "tcc-jwt-token":
//   //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NTksImVtYWlsIjoiaGV5QHNhaWYuZGV2IiwibmFtZSI6bnVsbCwiYWRtaW4iOmZhbHNlLCJyZWZlcnJhbGNvZGUiOiJSRUYtNWxsbWFRNVFPcGtRVFJDUUFrUC0tIiwiaWF0IjoxNjU5NDY2ODkxfQ.GX4pCNLslomaKrKP3nLiznYITYzdkRpaP3mfCjBHnGY",
//   // };
//   win.postMessage(
//     JSON.stringify({
//       key: "tcc-jwt-token",
//       data: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NTksImVtYWlsIjoiaGV5QHNhaWYuZGV2IiwibmFtZSI6bnVsbCwiYWRtaW4iOmZhbHNlLCJyZWZlcnJhbGNvZGUiOiJSRUYtNWxsbWFRNVFPcGtRVFJDUUFrUC0tIiwiaWF0IjoxNjU5NDY2ODkxfQ.GX4pCNLslomaKrKP3nLiznYITYzdkRpaP3mfCjBHnGY",
//     }),
//     "*"
//   );
// };

function App() {
  useEffect(() => {
    // try {
    //   window["BarcodeDetector"].getSupportedFormats();
    // } catch {
    //   window["BarcodeDetector"] = BarcodeDetectorPolyfill;
    // }
  }, []);

  return (
    <UserProvider>
      <Routes />
    </UserProvider>
  );
}

export default App;
