import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";

const useDialog = () => {
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    message: "",
    onConfirm: null,
  });

  const showAlert = (message) =>
    new Promise((resolve) => {
      setDialog({
        open: true,
        title: "Alert",
        message,
        onConfirm: () => {
          setDialog((prev) => ({ ...prev, open: false }));
          resolve();
        },
      });
    });

  const showConfirm = (message) =>
    new Promise((resolve) => {
      setDialog({
        open: true,
        title: "Confirm",
        message,
        onConfirm: (result) => {
          setDialog((prev) => ({ ...prev, open: false }));
          resolve(result);
        },
      });
    });

  const DialogComponent = () => (
    <Dialog open={dialog.open} onClose={() => dialog.onConfirm(false)}>
      <DialogTitle>{dialog.title}</DialogTitle>
      <DialogContent>
        <Typography>{dialog.message}</Typography>
      </DialogContent>
      <DialogActions>
        {dialog.title === "Confirm" ? (
          <>
            <Button onClick={() => dialog.onConfirm(false)}>Cancel</Button>
            <Button onClick={() => dialog.onConfirm(true)} autoFocus>
              OK
            </Button>
          </>
        ) : (
          <Button onClick={() => dialog.onConfirm()}>OK</Button>
        )}
      </DialogActions>
    </Dialog>
  );

  return { showAlert, showConfirm, DialogComponent };
};

export default useDialog;
